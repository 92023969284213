<template>
	
	<div class="upload-template">

		<div class="stepper theming tertiary-color">
			<div class="container-lg px-0">
				<div class="row">
					<div class="col-md-24">
						<div class="d-flex flex-row">

							<div class="stepper__steps flex-grow-1" v-for="(step, index) in steps" :key="index">

								<div v-if="index > 0" class="stepper__steps__line current d-none-">
									<hr>
								</div>

								<div class="stepper__steps__step">

									<button
										class="stepper__steps__step__icon"
										:class="index + ' ' + (((current + 1) == (index + 1)) ? 'hello- router-link-active' : 'bye-')">
										{{ index + 1 }}
									</button>

									<div class="stepper__steps__step__label">
										<p class="fw-bold">{{ step.label ? step.label : stepLabels[step.num - 1] }}</p>
									</div>

								</div>
								<div v-if="index < (steps.length - 1)" class="stepper__steps__line current">
									<hr>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="showScreen == 'terms'" class="terms-and-conditions-template">
			<div class="container-lg">
				<div class="row">
					<div class="col-24">
						<div class="terms-and-conditions-template__content">
							<div class="terms-and-conditions-template__content__title">
								<h1>Terms & Conditions</h1>
							</div>
							<div class="terms-and-conditions-template__content__content">
								<p>
									I agree, acknowledge and represent, that by personally submitting this application TwentyFirst Financial Inc. and/or its affiliates is authorized to obtain my credit report from one or more consumer credit reporting agencies, to verify the information in my credit report with third parties as necessary, and to periodically update my credit information with credit reporting agencies. In addition, I consent to accept, receive, and use in electronic form any loan agreement (and any amendments or renewals), disclosure statements, notices or other associated documents and/ or information that may be delivered to me
								</p>
							</div>
							<div class="terms-and-conditions-template__content__consent">
								<input
									v-bind="acceptTerms"
									v-model="vModels['acceptTerms']"
									type="checkbox"
									name="terms-and-conditions-checkbox"
									id="terms-and-conditions-checkbox"
									class="tffi-input-checkbox theming primary-color">
									I acknowledge and confirm the statement above.
							</div>
							<div class="terms-and-conditions-template__content__controls">
								<div class="tffi-navigation-bottom">
									<button type="submit" class="tffi-btn tffi-btn--solid theming secondary-color d-none" :loading="goingBack"
										@click="goBack({ name: 'ApplicationStep4', params: $route.params })">
										Decline
									</button>
									<span class="flex-grow-1"></span>
									<button class="tffi-btn tffi-btn--solid theming primary-color" type="submit" :loading="goingBack"
										@click="goToUploads">
										Accept
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


		</div>
		
		<div v-if="showScreen == 'uploads'" class="container-xl">
			<div class="row">
				<div class="col-24">
					<div class="template__page-title">
						<h1>Upload Files</h1>
					</div>
					<div class="template__page-description mb-5">
						<p>
							You are invited to submit documentation for your loan application. Select a document type on the left, and then click CHOOSE FILES to upload the document. Permitted documents types are PDF, PNG and JPG.
						</p>
					</div>
				</div>
			</div>

			<form @submit.prevent="onSubmit" novalidate>
				<div class="container-xl template--vh">
					<div class="row">
						<div class="col-lg-8">

							<div v-for="(type, index) in uploadTypes" v-if="uploadTypes[index]['isRequested'] && type.name != 'signedContract'" class="tffi-upload-card-mini d-flex flex-row my-1">
								<div @click="selectedType = index" class="flex-grow-1">
									<div class="tffi-card tffi-card-main-card d-flex flex-row p-4 upload-type" :class="(selectedType == index) ? 'active' : ''">
										<div class="d-flex flex-grow-1">
											<p class="my-0">{{ type.description }}</p>
										</div>
										<div>
											<div v-if="uploadTypes[index]['uploaded']" class="status-accepted">
												<img src="../../../assets/img/icons/check-mark.svg" alt="">
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="template--section-divider"></div>
							<div class="template--section-divider"></div>

						</div>
						<div class="col-lg-16">

							<div v-if="addingOtherDoc" class="tffi-card tffi-card-main-card other-document-card">
								<div class="row">
									<div class="col-24">
										<h2>
											Other Document
										</h2>
									</div>
								</div>

								<div class="row">
									<div class="col-24">
										<div class="d-flex flex-row">
											<div class="d-flex flex-column w-100">
												<label>Document Type</label>
												<input v-model="documentType" type="text" class="w-100">
											</div>
											<div class="px-4"></div>
											<div class="d-inline-flex align-items-center">
												<button @click="addUploadType(documentType)" class="tffi-btn tffi-btn--solid tffi-btn--bold theming accent-color primary-color-btn-solid px-5">
													Add
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div :class="$refs.upload && $refs.upload.dropActive && 'drop-active'" v-if="!addingOtherDoc" class="tffi-card tffi-card-upload-card theming primary-color">
								<p class="tffi-card-upload-card__title theming primary-color">Drag & Drop Files to Upload!</p>
								<div class="tffi-card-upload-card__image">
									<!-- <img src="../../../assets/img/icons/upload.svg" alt=""> -->
									<svg xmlns="http://www.w3.org/2000/svg" width="124.746" height="64.749" viewBox="0 0 124.746 64.749">
										<g id="Group_1108" data-name="Group 1108" transform="translate(-902 -346)">
											<g id="Group_374" data-name="Group 374" transform="translate(740.061 -309.916)">
											<g id="Group_373" data-name="Group 373" transform="translate(156.781 648)">
												<g id="Group_371" data-name="Group 371" transform="translate(23.912 14.288)">
												<path id="Path_4192" data-name="Path 4192" d="M1285,1333.284l-24.808,5.771c-.693.162-1.122.85-.869,1.935l10.555,45.374a1.322,1.322,0,0,0,1.451.565l34.257-7.969c.69-.161,1.118-.85,1.049-1.147l-8.357-35.923c-.139-.6-.262-.77-.432-.876l-12.261-7.634a.774.774,0,0,0-.585-.1Zm0,0" transform="translate(-1259.254 -1333.264)" fill="#fff" stroke="#c18f14" stroke-miterlimit="10" stroke-width="2"/>
												<path id="Path_4193" data-name="Path 4193" d="M1284,1333.468l2.357,10.131,10.126-2.355Zm0,0" transform="translate(-1257.86 -1333.252)" fill="#fff" stroke="#c18f14" stroke-linejoin="round" stroke-width="2"/>
												<line id="Line_292" data-name="Line 292" x2="9.484" transform="translate(11.953 19.483) rotate(-13)" fill="none" stroke="#c18f14" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/>
												<line id="Line_293" data-name="Line 293" x2="21.428" transform="translate(13.472 26.065) rotate(-13)" fill="none" stroke="#c18f14" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/>
												<line id="Line_294" data-name="Line 294" x2="21.428" transform="translate(14.992 32.646) rotate(-13)" fill="none" stroke="#c18f14" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/>
												<line id="Line_295" data-name="Line 295" x2="21.428" transform="translate(16.511 39.228) rotate(-13)" fill="none" stroke="#c18f14" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/>
												</g>
												<g id="Group_372" data-name="Group 372" transform="translate(69.157 4.802) rotate(20)">
												<rect id="Rectangle_799" data-name="Rectangle 799" width="42.555" height="42.555" rx="6.394" transform="translate(12.872 8.296)" fill="#fff" stroke="#c18f14" stroke-miterlimit="10" stroke-width="1.749"/>
												<path id="Path_4199" data-name="Path 4199" d="M1241.585,1391.816h32.61L1264.927,1375l-9.612,12.014-6.521-6.864Z" transform="translate(-1223.522 -1347.241)" fill="#d6b943"/>
												<circle id="Ellipse_39" data-name="Ellipse 39" cx="3.089" cy="3.089" r="3.089" transform="translate(22.183 14.375)" fill="#d6b943"/>
												</g>
											</g>
											</g>
											<g id="Group_1104" data-name="Group 1104" transform="translate(-524.254 -120)">
											<line id="Line_296" data-name="Line 296" x2="10" transform="translate(1539.5 472.5)" fill="none" stroke="#d6b943" stroke-linecap="round" stroke-width="3"/>
											<line id="Line_297" data-name="Line 297" y2="10" transform="translate(1544.5 467.5)" fill="none" stroke="#d6b943" stroke-linecap="round" stroke-width="3"/>
											</g>
											<g id="Group_1105" data-name="Group 1105" transform="translate(-621.922 -69.853)">
											<line id="Line_296-2" data-name="Line 296" x2="6.353" transform="translate(1539.5 470.676)" fill="none" stroke="#d6b943" stroke-linecap="round" stroke-width="3"/>
											<line id="Line_297-2" data-name="Line 297" y2="6.353" transform="translate(1542.676 467.5)" fill="none" stroke="#d6b943" stroke-linecap="round" stroke-width="3"/>
											</g>
											<g id="Group_1106" data-name="Group 1106" transform="translate(-636 -115.128)">
											<line id="Line_296-3" data-name="Line 296" x2="5.128" transform="translate(1539.5 470.064)" fill="none" stroke="#231f20" stroke-linecap="round" stroke-width="3"/>
											<line id="Line_297-3" data-name="Line 297" y2="5.128" transform="translate(1542.064 467.5)" fill="none" stroke="#231f20" stroke-linecap="round" stroke-width="3"/>
											</g>
											<g id="Group_1107" data-name="Group 1107" transform="translate(-526.818 -79.128)">
											<line id="Line_296-4" data-name="Line 296" x2="5.128" transform="translate(1539.5 470.064)" fill="none" stroke="#c18f14" stroke-linecap="round" stroke-width="3"/>
											<line id="Line_297-4" data-name="Line 297" y2="5.128" transform="translate(1542.064 467.5)" fill="none" stroke="#c18f14" stroke-linecap="round" stroke-width="3"/>
											</g>
										</g>
										</svg>
								</div>
								<p class="tffi-card-upload-card__or">or</p>
								<div>
									<file-upload
										:custom-action="customUpload"
										class="tffi-btn tffi-btn--solid tffi-btn--bold theming accent-color p-2 uppercase strong"
										type="button"
										:multiple="false"
										:drop="true"
										:drop-directory="true"
										v-model="files"
										accept=".pdf, .jpg, .png, .PNG"
										ref="upload"
										@input-file="inputFile"
										:timeout="600 * 100000"
									>Choose files</file-upload>

									<button
										type="button"
										class="btn btn-success d-none"
										v-if="!$refs.upload || !$refs.upload.active"
										@click.prevent="$refs.upload.active = true"
									>Start Upload</button>
								</div>
							</div>

							<hr class="my-5">

							<div v-if="Object.keys(uploaded).length > 0" class="tffi-card tffi-card-upload-progress-card">
								<div class="row">
									<div class="col-24">

										<!--item-->
										<div v-for="(file, index) in uploaded" v-if="file.uploaded" :key="file.name" class="tffi-card-upload-progress-card__item d-flex flex-row">
											<div class="tffi-card-upload-progress-card__item__left-area d-flex flex-column flex-grow-1 justify-content-center me-5">
												<div class="tffi-card-upload-progress-card__item__item-description d-flex flex-row">
													<div class="tffi-card-upload-progress-card__item__item-description__file-name">
														<p>{{ file.description }}</p>
													</div>
													<div class="flex-grow-1"></div>
													<div class="tffi-card-upload-progress-card__item__item-description__file-size">
														<p>{{ (file.size / 1024 / 1024).toFixed(2) }} MB</p>
													</div>
												</div>
												<div class="d-none tffi-card-upload-progress-card__item__progress-bar progress" style="height: 8px;">
													<div class="progress-bar theming accent-color" style="width:25%;" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
												</div>
												<div class="d-none- tffi-card-upload-progress-card__item__progress-percentage">
													<p class="theming primary-color">{{ file.fileName }}</p>
												</div>
											</div>
											<div class="tffi-card-upload-progress-card__item__right-area">
												<div class="tffi-card-upload-progress-card__item__controls d-flex align-items-center h-100">
													<button @click.prevent="removeFile(index)" class="tffi-card-upload-progress-card__item__controls__stop">
														<img src="../../../assets/img/icons/stop.svg" alt="">
													</button>
												</div>
											</div>
										</div>

									</div>

									<div class="col-lg-24">

									</div>
								</div>
							</div>

						</div>
					</div>
				</div>

				<div class="tffi-navigation-bottom">
					<div class="container-xl">
						<div class="row">
							<div class="col order-3 order-lg-1 col-24 col-lg-16 d-flex justify-content-start">

							</div>
							<div class="col order-2 order-lg-2 col-24 col-lg-4 d-flex justify-content-end">

							</div>
							<div class="col order-1 order-lg-3 col-24 col-lg-4 d-flex justify-content-end">
								<button
									class="tffi-btn tffi-btn--solid theming primary-color me-0"
									@click="showScreen = 'confirmation'"
									>
									Done
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>

		<div class="confirmation-card-template mt-5" v-if="showScreen == 'confirmation'">
			<div class="container-xl template--vh">
				<div class="row h-100 d-flex align-items-center justify-content-center">
					<div class="col-24">
						<div class="tffi-card tffi-card-confirmation-card mx-auto">
							<i class="tffi-card-confirmation-card__icon fa-solid fa-circle-check theming secondary-color"></i>
							<p class="tffi-card-confirmation-card__text">
								Thank you for submitting documentation.
								</p>
							<button href="#" class="d-none tffi-btn tffi-btn--solid theming accent-color mx-auto-lg fw-bold px-5">
								Ok
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>

</template>

<script>

	// import ApplicationMixin from '../ApplicationForms/Application/ApplicationMixin';
	import RadialProgressBar from 'vue-radial-progress';
	import { paramCase, camelCase } from 'change-case';
	import FormsMixin from './../../application/components/ApplicationForms/FormsMixin';
	import { upload, getApplication } from '../../api';
	import FileUpload from 'vue-upload-component';

	export default {

		components: {
			RadialProgressBar,
			FileUpload
		},

		mixins: [
			FormsMixin
		],

		data() {

			return {
				showScreen: 'terms',
				acceptTerms: null,
				uploadFilesScreen: false,
				checkbox: {
					acceptTerms: {
						type: "checkbox",
						required: true,
						label: "Lorem ipsum dolor sit amet, consectetur adipiscing elit"
					}
				},

				steps: [{
					num: 1,
					name: 'Terms and Conditions',
					label: 'Terms and Conditions',
				}, {
					num: 2,
					name: 'Upload Files',
					label: 'Upload Files',
				}],
				current: 0,
				total: 2,
				
				addingOtherDoc: false,
				documentType: '',
				url: process.env.VUE_APP_ENDPOINT,
				files: [],
				selectedType: 0,
				selectedTypeName: 'signedContract',
				uploadTypeNames: [
					"Signed Contract",
					"Bill of Sale / Invoice",
					"Completion Certificate",
					"PAP Form / Void Cheque",
					"Government ID",
					"Income Verification"
				],
				uploaded: {},
				uploadTypes: {
				},
				isFilesUploaded: false,
				vModels: {
					acceptTerms: false
				}
			};

		},

		async mounted() {

			this.showScreen = 'terms'

			this.saveLoading(true);

			console.log(' this.$route.params', this.$route);
			// return;

			// let referralOther = document.getElementById('referral-other');
			// referralOther.parentElement.style.display = 'none';

			let id = this.$route.params.applicationId
			let token = this.$route.query.token

			this.app = null

			// this.app = await getApplication(id, token)

			let errorMsg = 'Error';

			let res = await getApplication(id, token).catch(async err => {

				console.log('err', err);

				if (err.response && err.response.data && err.response.data.errorMessage) {

					if (err.response.data.errorMessage == 'Invalid token') {
						errorMsg = 'Token expired. Request a new invite from dealer.';
					} else {
						errorMsg = err.response.data.errorMessage;
					}

				} else if (err.message && err.message !== 'Network Error') {

					errorMsg = err.message;

				}

				this.errorAlert = await this.showErrorAlert(errorMsg);

				this.showScreen = 'none';

				this.saveLoading(false)

			});

			console.log('res', res);

			this.app = res

			// console.log('showScreen true');

			if (this.app.requestUploadTypes) {
				this.uploadTypes = this.app.requestUploadTypes

				Object.keys(this.uploadTypes).forEach(element => {
					if(
						this.uploadTypes[element]['isRequested']
						&& this.uploadTypes[element].name != 'signedContract'
						&& !this.selectedType
					) {
						this.selectedType = element
						console.log('this.selectedType', this.selectedType);
					}
				});
				this.listItems();
			}

			if (this.app !== undefined) {
				// this.showScreen = true;
			}

			console.log('this.app', this.app);

			// this.applicant = {
			// 	firstName: this.app.firstName,
			// 	lastName: this.app.lastName,
			// 	email: this.app.email
			// }

			// this.coApps = this.app.coApps

			// this.vModels = this.app

			// this.vModels.program = this.app.program

			// console.log('vModels', this.vModels);

			// console.log('this.app.coApps', this.app.coApps);

			// this.programType = this.vModels.program.program.Code == 2 ? 'Lease' : 'Loan'

			if (
				this.app
				&& this.app.meta
				&& this.app.meta.color1
			) {

				const rootElement = document.documentElement;

				this.app.meta.color1 ? rootElement.style.setProperty('--tffi-primary-color', this.app.meta.color1) : '';
				this.app.meta.color2 ? rootElement.style.setProperty('--tffi-secondary-color', this.app.meta.color2) : '';
				this.app.meta.color3 ? rootElement.style.setProperty('--tffi-tertiary-color', this.app.meta.color3) : '';
				this.app.meta.color4 ? rootElement.style.setProperty('--tffi-accent-color', this.app.meta.color4) : '';
			}

			this.saveLoading(false);

		},

		methods: {

			async listItems() {

				console.log('this.listItems');

				Object.keys(this.uploadTypes).forEach((index, element) => {
					// console.log('element', this.uploadTypes[element]);
					if (
						this.uploadTypes[index]['uploaded']
						&& this.uploadTypes[index]['isRequested']
					) {
						console.log('adding index', index);
						this.uploaded[index] = this.uploadTypes[index]
					}
				});

				// console.log('this.uploaded after ', this.uploaded);

			},

			async removeFile(index) {

				this.saveLoading(true);

				// console.log('index', index);

				this.uploadTypes[index].uploaded = false;

				// console.log('this.uploaded before', this.uploaded);
				// this.uploaded = null;
				// this.uploaded = this.uploadTypes;
				this.listItems();
				
				let saveResult = await this.saveApplicationAction({
					data: {
						id: this.$route.params.applicationId,
						uploadTypes: this.uploadTypes
					},
					// errors: this.errors
				});

				this.saveLoading(false);

			},


			async customUpload(file, component) {

				let abvUploadType = {
					signedContract: "SC",
					billOfSaleInvoice: "BOS",
					completionCertificate: "CC",
					papFormVoidCheque: "VC",
					governmentId: "ID",
					incomeVerification: "PIC",
					audioFile: "AUD"
				}

				this.saveLoading(true);

				console.log('customUpload');
				console.log('file', file);

				let ext = file.file.name.split('.').pop();

				let edgeId = this.app.edgeId ?? 'XXXX'
				
				let fileName = edgeId + '-' + (abvUploadType[this.uploadTypes[this.selectedType].name] ?? ('OTH-' + this.uploadTypes[this.selectedType].name)) + '-' + file.file.name

				console.log('fileName', fileName);

				let payload = {
					uploadType: this.uploadTypes[this.selectedType].name,
					id: this.$route.params.applicationId,
					contentType: file.file.type,
					fileName
				};
				
				let result = await upload(payload, file.file);

				this.uploadTypes[this.selectedType].uploaded = true
				this.uploadTypes[this.selectedType].fileName = fileName
				this.uploadTypes[this.selectedType].size = file.file.size

				console.log('result', result);

				let saveResult = await this.saveApplicationAction({
					data: {
						id: this.$route.params.applicationId,
						token: this.app.token,
						uploadTypes: this.uploadTypes
					},
					// errors: this.errors
				});

				console.log('saveResult', saveResult);

				this.listItems()
				// this.uploaded = this.uploadTypes;

				this.saveLoading(false);
			},

			inputFile(newFile, oldFile) {

				if (
					(newFile && !oldFile) // add
					||
					(newFile && oldFile) // update
				) {

					// add
					console.log('add', newFile);

					let type = newFile.type;

					console.log('type', type);

					if (

						!type

						||

						(
							type !== 'application/pdf'
							&& type !== 'image/jpeg'
							&& type !== 'image/png'
							&& type !== 'image/heic'
							&& type !== 'image/heif'
						)

					) {

						console.log('wrong type');
						this.files = [];
						this.showErrorAlert(this.$i18n.t('uploads.fileTypesAccepted'));

					} else {
						console.log('setting refs active to true');
						this.$refs.upload.active = true
					}

				}

				if (!newFile && oldFile) {

					// remove
					console.log('remove', oldFile);

				}

			},

			paramCase: val => paramCase(val),

			async goToConfirmation() {

				this.showConfirmationPage = true

			},

			async goToUploads() {

				console.log('goToUploads');
				console.log('this.vModels.acceptTerms', this.vModels.acceptTerms);

				this.saveLoading(true);

				if (
					this.vModels.acceptTerms === false
					// || this.vModels.acceptTerms.target === undefined
					// || this.vModels.acceptTerms === false
				) {

					this.errorAlert = await this.showErrorAlert('Please check accept terms checkbox');

				} else if (
					this.vModels.acceptTerms
				) {

					this.showScreen = 'uploads'
					this.current = 1

				} else {
					this.errorAlert = await this.showErrorAlert('Please check accept terms checkbox');
				}

				this.saveLoading(false);

			}


		}

	};

</script>

<style lang="scss" scoped>
	.upload-type {
		cursor: pointer;

		&.active {
			border: solid #C18F14 2px
		}

	}

</style>
