<template>

	<div>
		<!--e-signature-template-->
		<div class="e-signature-template">
			<steps :current="1" :total="4" />

			<div class="container-xl">
				<div class="row">
					<div class="col-24">
						<div class="template__page-title">
							<h1>GENERATE CONTRACT</h1>
						</div>
						<div class="template__page-description">
							<p>
								Choose a method for executing the contract.
							</p>
						</div>
					</div>
				</div>
			</div>

			<div class="mb-5"></div>

			<form @submit.prevent="onSubmit" novalidate>
				<div class="container-xl template--vh">
					<div class="row">
						<div class="col-lg-12">
							<div
								:class="'tffi-card tffi-card-main-card e-signature-card ' + ((app && app.application && app.application.signNowInvite == 'eSignature') ? 'active' : '')">
								<div class="row">
									<div class="col-lg-12 d-flex align-items-center">
										<p class="my-0">
											Route the contract to all parties’ emails for e-signature
										</p>
									</div>
									<div class="col-lg-12 d-flex align-items-center justify-content-center justify-content-xl-end">
										<button @click="sign('eSignature')" :disabled="isDisableButtons"
											class="tffi-btn tffi-btn--solid theming primary-color mx-auto-lg w-100 fw-bold">
											ROUTE FOR E-SIGNATURE
										</button>
									</div>
								</div>
							</div>
						</div>

						<div class="col-lg-12">
							<div
								:class="'tffi-card tffi-card-main-card e-signature-card ' + ((app && app.application && app.application.signNowInvite && app.application.signNowInvite.includes('onlyDealer', 'embedded')) ? 'active' : '')">

								<div class="row">
									<div class="col-lg-12 d-flex align-items-center">
										<p class="my-0" v-if="isGenerateForPrintingEnabled">
											Prepare the contract for signing in person
										</p>
										<p class="my-0" v-if="isAllSignHereEnabled">
											All parties will sign now on this device
										</p>

										<p class="my-0" v-if="inPersonSignNowLinks.length > 0">
											When each party is ready to sign, find and click their button below. Each party will review and
											sign the contract in another browser tab. After they have signed, return to this browser tab.
										</p>
									</div>
									<div class="col-lg-12 d-flex align-items-center justify-content-center justify-content-xl-end">
										<button v-if="isGenerateForPrintingEnabled" @click="sign('onlyDealer')" :disabled="isDisableButtons"
											class="tffi-btn tffi-btn--solid theming primary-color mx-auto-lg w-100 fw-bold">
											GENERATE PDF FOR PRINTING
										</button>

										<button v-if="isAllSignHereEnabled" @click.prevent="sign('embedded')"
											:disabled="isAllSignHereButtonDisabled"
											class="tffi-btn tffi-btn--solid theming primary-color mx-auto-lg w-100 fw-bold">
											ALL SIGN HERE
										</button>
									</div>
								</div>

								<div class="row mt-5" v-for="(item, key) in inPersonSignNowLinks" :key="key">
									<div class="col-lg-12 d-flex align-items-center">
										<p class="my-0">
											Click here when {{ item.name }} is ready to sign
										</p>
										<p>{{ item.name }} has signed</p>
									</div>
									<div class="col-lg-12 d-flex align-items-center justify-content-center justify-content-xl-end">
										<button v-if="isAllSignHereEnabled" @click="item.isClicked = true; openLink(item.link)"
											:disabled="item.isClicked ?? false"
											class="tffi-btn tffi-btn--solid theming primary-color mx-auto-lg w-100 fw-bold">
											<span v-if="!item.isClicked">READY TO SIGN</span>
											<span v-if="item.isClicked"><img
													v-if="(item.email && signedEmails.includes(item.email.toLowerCase()) || signedEmails.includes(app.application.signLinks[index + 2]?.email))"
													class="" src="../../../assets/img/icons/check-mark.svg" alt=""> SEE OTHER TAB</span>
										</button>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</form>
		</div>

	</div>

</template>

<script>

	// import ApplicationMixin from '../../application/components/ApplicationForms/Application/ApplicationMixin';
	import FormsMixin from './ApplicationForms/FormsMixin';
	import { mapActions } from 'vuex';
	import axios from 'axios';
	import { mapState } from 'vuex';
	import { sendEmailInvite, sendUploadTrigger, getApplication, getSignNowStatusApi, downloadPdf } from '../../api';
	// import store from '../../store';
	// import { saveLoading } from '../../vuex/actions';
	// import dotCase from 'dot-case';
	import { paramCase } from 'change-case';
	// import fs from 'fs';
	const iconv = require('iconv-lite');


	
	axios.defaults.headers.common['Content-Type'] = 'application/json';
	axios.defaults.headers.common['Accept'] = 'application/json';

	export default {

		mixins: [
			// ApplicationMixin
			FormsMixin
		],

		data() {

			return {
				isDisableButtons: false,

				isAllSignHereEnabled: false,
				isAllSignHereButtonDisabled: false,

				isGenerateForPrintingEnabled: true,
				inPersonSignNowLinks: {},
				app: null,
				isDocSigned: false,
				urls: null,
				isSigned: {
					agent: false,
					customer: false,
					coCustomer: false,
				},
				signees: [
					'customer',
					'coCustomer'
				],
				allSigned: false,
				envelopeId: null,
				customerSignMethod: null,
				coCustomerSignMethod: null,
				hasCoApplicant: null,
				fetchingApp: true,
				isTimeToCheckStatus: false,
				closeTab: false,
				isEmailSentToCustomer: false,
				isCoCustomerSigned: false,
				isQC: false,
				isShowPopupNotice: false,
				intervalId: null
			};

		},

		watch: {
			'isDocSigned': {

				handler(val) {

					if (val) {

						// this.navToUploads();

					}

				}
				
			},
			'isTimeToCheckStatus': {

				handler(val) {

					if (val) {

						console.log('isTimeToCheckStatus', this.isTimeToCheckStatus);

						this.checkStatus()

					}

				}

			}

		},

		async mounted() {

			this.saveLoading(true);

			let id = this.$route.params.applicationId

			this.app = await getApplication(id);

			if (this.app.application.signNowInvite) {
				this.isDisableButtons = 'disabled'
			}

			console.log('this.app', this.app);

			try {
				if (
					this.app.application.signNowSent
				) {

					this.checkSignStatus()

					this.intervalId = setInterval(this.checkSignStatus, 15000);

				}
			} catch (error) {
				console.log('error', error);
			}

			if (this.authUser.isTabletSignAllowed) {
				this.isAllSignHereEnabled = true
				this.isGenerateForPrintingEnabled = false
			}
			
			this.saveLoading(false);

		},

		beforeUnmount() {
			// console.log('beforeUnmount-------------------------');
			clearInterval(this.intervalId);
		},

		beforeRouteLeave(to, from, next) {
			// console.log('beforeRouteLeave-------------------------');
			clearInterval(this.intervalId);
			next();
		},


		// onBeforeUnmount() { clearInterval(intervalId) },

		// async unmounted() {
		// 	console.log('onUnmounted-------------------------');
			
		// 	clearInterval(this.intervalId);
		// },

		// watchEffect() {
		// 	console.log('watchEffect-------------------------');
		// 	route.fullPath; // React to route changes
		// 	clearInterval(this.intervalId);
		// },

		// onBeforeRouteLeave() {
		// 	console.log('onBeforeRouteLeave-------------------------');
		// 	route.fullPath; // React to route changes
		// 	clearInterval(this.intervalId);
		// },

	computed: {

		...mapState({
			authUser: state => state.auth.authUser,
		}),

	},

		methods: {

			...mapActions([
				'showErrorAlert',
				'showInfoAlert',
				'removeAlerts'
			]),

			getUrl(id) {

				return {
					name: 'UploadFiles',
					params: {
						status: paramCase(id)
					}
				};

			},

			async checkSignStatus() {

				let id = this.$route.params.applicationId

				this.signedEmails = await getSignNowStatusApi({ id })

				await this.signedEmails.forEach((element, i) => {
					this.signedEmails[i] = element.replace(' (dev)', '')
				});

			},

			async getApp() {

				this.saveLoading(true);

				let app = await getApplication(this.$route.params.applicationId);
				console.log('app', app);

				if (
					app.application
					&& !app.application.creditProgramID
				) {

					this.showErrorAlert('Please select a program first.');

					return this.$router.push({
						name: 'FinancingProgram',
						params: this.$route.params
					});

				}

				this.hasCoApplicant = app.application.hasCoApplicant == 'Yes' ? true : false;
				this.isDocSigned = app.application.isDocSigned;

				if (app.application.envelopeId) {

					this.envelopeId = app.application.envelopeId;

					await this.checkStatus();

				}

				this.fetchingApp = false;

				this.saveLoading(false);

			},

			navToUploads() {

				return this.$router.push({
					name: 'UploadFiles',
					params: this.$route.params
				});
			
			},

			async download(event) {

				this.saveLoading(true);

				let data;

				try {
					
					data = await downloadPdf({
						id: this.$route.params.applicationId,
					});

				} catch (error) {

					this.saveLoading(false);
					console.log('data', data);
					this.showErrorAlert(error);

				}

				let byteCharacters = atob(data.base64);
				let byteNumbers = new Array(byteCharacters.length);
				for (let i = 0; i < byteCharacters.length; i++) {
					byteNumbers[i] = byteCharacters.charCodeAt(i);
				}
				let byteArray = new Uint8Array(byteNumbers);
				let file = new Blob([byteArray], { type: 'application/pdf;base64' });
				let fileURL = URL.createObjectURL(file);
				let newWin = window.open(fileURL);

				if(!newWin || newWin.closed || typeof newWin.closed=='undefined') {
					let errorMsg = this.$i18n.t('sign.popupNotice');
					this.showErrorAlert(errorMsg);
					this.isShowPopupNotice = true;
				}

				this.saveLoading(false);

			},

			async openLink(link) {

				window.open(link, '_blank');

			},

			async sign(method) {

				/* debug */
				// this.inPersonSignNowLinks = {
				// 	"0": {
				// 		"link": "https://app.signnow.com/webapp/document/5a317ee191794c6ca08b4312b0730891cbf0c786?access_token=0ba7163a483786b824c3c80ae652c6e788ac7fb2436da528754e84408b778524&route=fieldinvite",
				// 		"name": "Siva",
				// 		"isClicked": false
				// 	},
				// 	"1": {
				// 		"link": "https://app.signnow.com/webapp/document/5a317ee191794c6ca08b4312b0730891cbf0c786?access_token=8a4740d8b5e49c3ee2f3da083e8c9eff27279e77b33bfe7aca991a80e70d65c8&route=fieldinvite",
				// 		"name": "siva",
				// 		"isClicked": false
				// 	},
				// 	"2": {
				// 		"link": "https://app.signnow.com/webapp/document/5a317ee191794c6ca08b4312b0730891cbf0c786?access_token=06f6307ea9bac15618b99826020ec22ad450cd17f4d5d95f9c0b17f3db410f70&route=fieldinvite",
				// 		"name": "siva2",
				// 		"isClicked": false
				// 	}
				// }
				// return


				this.isAllSignHereButtonDisabled = true

				this.removeAlerts();

				if (!this.$route.params.applicationId) {

					this.showErrorAlert('Input application id in the URL');
					
					return {
						errorMessage: 'Application Id not found'
					};
				
				}

				let id = this.$route.params.applicationId;
				
				this.saveLoading(true);

				try {
					
					let data = await sendEmailInvite({
						method,
						id
					});
					
					console.log("data ==> ", data);

					if (method == 'embedded') {

						this.inPersonSignNowLinks = data
						this.saveLoading(false);
						return

					}

					let result = JSON.parse(data)

					console.log('result', result);

					if (
						result.status
						&& result.status == 'success'
					) {

							this.showSuccessAlert('The invite has been sent.');

					} else {
						this.showErrorAlert('Something went wrong.' + JSON.stringify(data));
					}
	
					console.log('data', data);

				} catch (error) {

					console.log("error ==> ", error);

					if (error.response.data.errorMessage == 'Unable to find a route to match the URI: document/asd') {
						this.showErrorAlert('signNow template id is wrong.');
					} else {
						this.showErrorAlert('Something went wrong: ' + JSON.stringify(error.response.data.errorMessage));
					}

					this.saveLoading(false);
					return

				}

				this.saveLoading(false);

				return this.$router.replace({
					name: 'BankVerification',
					params: this.$route.params
				});

			}

		}

	};

</script>

<style lang="scss" scoped>
.sign-links {
	list-style: none;
	padding-left: 0px;
}
select {
	display: inline!important;
}
.active {
	border: 2px solid var(--tffi-primary-color);
}
</style>