<template>
	<div class="dashboard-section">
		<section class="dashboard-section__action-section border-radius--4px box-shadow--60px">
			<div class="container-fluid px-0">
				<div class="row">
					
					<div v-if="this.isLoanFound" class="col-12 col-sm-12 col-md-6 col-lg-4">
						<router-link tag="div" :to="{ name: 'PaymentCalculator', params: {} }">
							<button class="tffi-btn tffi-btn--solid w-100 theming primary-color tffi-card__action-card dashboard-main-action-btn" tag="a" color="success" size="xl">
								New Loan Application
							</button>
						</router-link>
					</div>
					
					<div v-if="isLeaseFound" class="col-12 col-sm-12 col-md-6 col-lg-4">
						<router-link tag="div" :to="{ name: 'LeaseCalculator', params: { applicationId: 'new' } }">
							<button class="tffi-btn tffi-btn--solid w-100 theming secondary-color tffi-card__action-card dashboard-main-action-btn" tag="a" color="primary" size="xl"><icon name="add" type="tffi-icon" class="mr-3 font-lg" />New Lease Application</button>
						</router-link>
					</div>

				</div>
			</div>
		</section>

		<section class="dashboard-section__filter-section d-none">
			<div class="container-fluid px-0">
				<div class="row d-flex align-items-center">
					<div class="col-24 col-lg-1">
						<h2>Filter: </h2>
					</div>
					<div class="col-24 col-lg-5">
						<input type="text" class="my-0 w-100">
					</div>
					<div class="col-24 col-lg-5">
						<input type="text" class="my-0 w-100">
					</div>
					<div class="col-24 col-lg-13">
						<input type="text" class="ml-2 my-0 w-100">
					</div>
				</div>
			</div>
		</section>

		<section class="dashboard-section__datatable-section border-radius--4px box-shadow--60px">
			<div class="container-fluid px-0">
				<div class="row">
					<div class="col-24">
						<datatable :data="databoxes" :loading="isLoading" :locale="locale" display-style="list" :order="[1, 'desc']" ref="databoxes">

							<router-link tag="div" :to="{ name: 'LeaseCalculator', params: {} }">
								<btn tag="a" color="success" size="xl">
									<i class="fa fa-calculator" style="font-size:25px;margin-right:10px;"></i>Lease Calculator
								</btn>
							</router-link>

							<template v-slot:toolsLeft>

								<btn size="sm" color="info" class="ml-3 d-none- tffi-btn tffi-btn--solid tffi-btn--bold theming primary-color" @click="syncApps()">
									<icon name="sync" size="lg"></icon>
								</btn>

								<transition>
									<span v-if="syncingMsg" class="label label-success">Sync Finished</span>
								</transition>

							</template>

							<template v-slot:column-status="{ item, itemKey }">
								<span :class="tagClass(item)">
									{{ capitalCase(item.display) }}
								</span>
							</template>

							<template v-slot:column-actions-column="{ item, itemKey }">

								<ul class="list-unstyled d-block w-100 p-0 mb-0 d-flex">

									<spinner v-if="actions[item.id] && !actions[item.id].resolved" :height="30" :width="30" color="#008093"
										class="d-none-"></spinner>

									<template v-else v-for="(btn, btnKey) in actions[item.id].btns">

										<btn type="button" :key="itemKey + '-' + btnKey + '-btn'" :loading="spinning[itemKey + '-' + btnKey]"
											:spinner-size="16" :spinner-absolute="true" class="tffi-btn tffi-btn--solid theming primary-color w-100"
											@click="confirmEventAndGo(btn, btnKey, itemKey + '-' + btnKey)" v-bind="getBtnAttrs(btn)"
											>
											{{ getBtnLabel(btn, btnKey) }}
											<i v-if="btn.HTMLlabel" v-html="btn.HTMLlabel"></i>
										</btn>

									</template>

									<btn :id="'get_perm_' + item.id" size="sm" color="info" class="d-none ml-3- btn-block mt-3"
										@click="retryPermissions(item.id)" :loading="syncing" :spinner-size="17">
										<icon name="sync" size="lg"></icon> Get Actions Available
									</btn>

								</ul>

							</template>

						</datatable>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>

import Spinner from 'vue-loading-overlay/src/loaders/spinner';
import Datatable from '../../elements/components/Datatable';
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import { pascalCase, paramCase, capitalCase } from 'change-case';
import { bankVerification, getPrograms, search, getPermissions, sync, cancelPending } from '../../api';
import { formatCurrency } from '../../elements/globalDirectives/CurrencyLocale';
import axios from 'axios';
import { resetApplicationAction } from '../vuex/actions';

let source = axios.CancelToken.source();

export default {

	components: {
		Spinner,
		Datatable
	},

	data() {

		return {

			syncing: false,
			syncingMsg: false,
			isLeaseFound: false,
			isLoanFound: false,

			applications: [],

			databoxes: {
				rows: [],
				columns: []
			},

			actions: {},
			spinning: {},

			isLoading: true,

			errorAlert: null,
			confirmAlert: null

		};

	},

	computed: {

		...mapState({
			locale: state => state.app.locale,
			authUser: state => state.auth.authUser
		}),

		applicationFieldNames() {

			let names = [
				'id',
				'created',
				'lastName',
				'firstName',
				// 'middleInitial',
				'fullName',
				'edgeStatus',
				'edgeId',
				'amount',
				'primaryPhone',
				'address1',
				'city',
				'dealerName',
				'actionsColumn'
			];

			if (
				['all'].includes(this.$route.params.status)
				|| !this.$route.params.status
				) {

				['firstName', 'lastName'].forEach(k => {

					let pos = names.indexOf(k);
					names.splice(pos, 1);

				});

			} else {

				let pos = names.indexOf('fullName');
				names.splice(pos, 1);

			}

			if (this.$route.params.status === 'incomplete') {

				let pos = names.indexOf('edgeId');
				names.splice(pos, 1);

				pos = names.indexOf('status');
				names.splice(pos, 1);
				
				
			}

			// let pos = names.indexOf('amount');
			// names.splice(pos, 1);

			return names;

		}

	},

	watch: {

		applications: {
			handler(val) {

				this.synchronize(val);

			},
			immediate: true
		}

	},

	created() {

		console.log('this', this);

		this.setName(this.$route);

		this.loadRows();

	},

	async mounted() {
		
		this.loadSearch(this.$route);
		
		await this.getProgramsAction()
		
		this.application = null

	},

	beforeDestroy() {

		this.databoxes.columns = {};
		this.databoxes.rows = {};
		this.removeAlert(this.errorAlert);
		this.removeAlert(this.confirmAlert);

	},

	methods: {

		...mapActions([
			'saveTitle',
			'showErrorAlert',
			'removeAlert',
			'removeAlerts',
			'confirm'
		]),

		capitalCase: s => capitalCase(s),
		isPromise: (p) => p && Object.prototype.toString.call(p) === "[object Promise]",

		setName(to) {

			this.saveTitle(this.$i18n.t('titles.' + pascalCase('All')));

		},

		tagClass(item) {

			return 'status-tag status-tag-' + paramCase(item.value);

		},

		async confirmEventAndGo(btn, btnKey, key) {

			await this.removeAlerts();
			this.$set(this.$data, 'spinning', {});

			if (btn.confirm === false) {

				return this.$router.push(btn.to);

			}

			this.$set(this.spinning, key, true);

			let label = this.getBtnLabel(btn, btnKey);
			let msg = this.getBtnMsg(btn, btnKey);

			this.confirmAlert = await this.confirm({
				message: msg,
				buttons: [
					{ text: this.$i18n.t('common.yes'), action: () => this.$router.push(btn.to), bold: false },
					{ text: this.$i18n.t('common.no'), action: (toast) => this.$snotify.remove(toast.id) }
				]
			});

			this.confirmAlert.on('hidden', () => {

				this.confirmAlert = null;
				this.$set(this.spinning, key, false);

			});

		},

		/*addParamsToLocation(params) {

			history.pushState(
				{},
				null,
				this.$route.path +
				'?' +
				Object.keys(params)
					.map(key => {
						return (
							encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
						)
					})
					.join('&')
			)

		},*/

		loadSearch(to) {

			if (to.query && to.query.q) {

				if (this.$refs.databoxes) {

					this.$set(this.$refs.databoxes, 'searchModel', to.query.q);
					this.$refs.databoxes.updateSearch(to.query.q);

				}

			}

		},

		async loadRows() {

			this.applicationFieldNames.forEach(fieldName => {

				this.databoxes.columns.push({
					label: this.$i18n.t('applications.' + fieldName),
					field: fieldName,
					sort: (fieldName === 'actionsColumn') ? false : 'asc',
					hidden: (fieldName === 'id')
				});

			});

			await this.getApplications();

		},

		async getApplications() {

			let status;

			if (this.$route.params.status) {
				status = 'All'; //pascalCase(this.$route.params.status);
			} else {
				status = 'All'
			}

			let apps = await search(status).catch(async error => {

				this.errorAlert = await this.showErrorAlert(this.$i18n.t('fatalError.fetchingContracts'));

			});

			this.$set(this.$data, 'applications', apps);

			this.isLoading = false;

		},

		fetch(id, arr) {

			arr = this.databoxes.rows || arr;

			return arr.find(item => (item.id === id || (item.application && item.application.id === id)));

		},

		async syncApps() {

			// console.log('reload');
			
			// location.reload();

			// return;
			// this.syncing = true;

			// let syncResult = await sync();

			// this.syncingMsg = true;
			// this.syncing = false;

			setTimeout(() => {

				this.syncingMsg = false;

				location.reload();

			}, 1);

		},

		async retryPermissions(id) {

			this.syncing = true;

			// let source = axios.CancelToken.source();

			await cancelPending(source);

			source = axios.CancelToken.source();

			let perms = await this.getPermissionBtns({
				application: {
					id: id
				},
				source: source
			});

			// this.stopAllRequests = true;

			this.syncing = false;
			// this.showGetPermissionsBtn = false;

		},

		async wait(time) {
			await new Promise(resolve => setTimeout(resolve, time));
		},

		synchronize() {

			if (!this.applications) {
				this.databoxes.rows.length = 0;
				return;
			}

			this.databoxes.rows.forEach(item => {

				let found = this.fetch(item.id, this.applications);

				if (!found) {

					// remove from results because gone
					this.databoxes.rows.splice(this.databoxes.rows.indexOf(item), 1);

				}

			});

			this.applications.forEach(item => {

				if (!this.fetch(item.application.id)) {

					// add to results because new

					let obj = {
						id: item.application.id
					};

					this.applicationFieldNames.forEach(name => {

						// console.log('name', name);

						if (['fullName'].includes(name)) {

							let fullName = (item.application['firstName'] || '') + ' ' + (item.application['lastName'] || '');

							obj[name] = {
								display: fullName != ' ' ? fullName : '-',
								value: fullName
							};

						} else if (['edgeStatus'].includes(name)) {

							obj[name] = {
								display: item.application['status'],
								value: item.application['status']
							};

						} else {

							obj[name] = {
								display: item.application[name] || '-',
								value: item.application[name]
							};

						}

						// if (name === 'dealerName') {
						// 	obj[name] = {
						// 		display: item.application[name] || '-',
						// 		value: item.application[name]
						// 	};
						// }

						if (name === 'created' && obj[name] !== '-' && obj[name].value) {

							let dt = moment(obj.created.value);
							obj.created.value = parseInt(obj[name].value);

							obj.created.display = dt.format('MMM Do YYYY');
							obj.created.display += '<br /><span class="text-muted">' + dt.format('hh:mm a') + '</span>';

						} else if (name === 'address1' && item.application.address2) {

							if (obj.address1.display === '-') {

								obj.address1.display = '';
								obj.address1.value += item.application.address2;

							} else {

								obj.address1.display += '<br />';
								obj.address1.value += ' ' + item.application.address2;

							}

							obj.address1.display += item.application.address2;

						} else if (['amount'].includes(name)) {

							try {

								// console.log('item.application.program.financedAmount', item.application.program.financedAmount);

								if (item.application.program.program.Code == '2') {

									obj[name].value = parseFloat(item.application.program.totalPayments.toFixed(2));
									obj[name].display = formatCurrency(item.application.program.totalPayments.toFixed(2), 'en');

								} else {

									obj[name].value = parseFloat(item.application.program.financedAmount.toFixed(2));
									obj[name].display = formatCurrency(item.application.program.financedAmount.toFixed(2), 'en');

								}

							} catch (error) {

							}

						}

					});

					if (!this.actions[item.application.id]) {

						this.actions[item.application.id] = this.getPermissionBtns(item);

					}

					obj.actionsColumn = {
						display: null,
						value: null,
						id: item.application.id
					};

					this.databoxes.rows.push(obj);

					// end of "add to results because new"

				}

			});

		},

		async getPermissionBtns(item) {

			let id = item.application.id;
			let status = item.application.status;
			let edgeStatus = item.application.edgeStatus;

			let obj = {
				resolved: true,
				btns: {}
			};

			if (['Incomplete', ].includes(status)) {

				obj.btns = {

					application: {
						color: 'info',
						to: {
							name: 'ApplicationStep4',
							params: {
								applicationId: id
							}
						},
						label: "Continue Application",
						class: 'my-1 w-100 actions__continue-application-btn',
						confirm: false
					},

					delete: {
						color: 'info',
						label: ' ',
						HTMLlabel: '<i class="fa fa-trash"></i>',
						msg: 'Are you sure you want to delete the application?',
						to: {
							name: 'ApplicationEvent',
							params: {
								applicationId: id,
								event: 'delete'
							}
						},
						class: 'my-1 w-100 actions__delete-btn',
						style: 'color:white; background-color:red',
						confirm: true
					}

				};

			} else if (['More Credit Info Needed', 'Not Approved'].includes(status)) {

				obj.btns = {

					application: {
						color: 'info',
						to: {
							name: 'ApplicationStep4',
							params: {
								applicationId: id
							}
						},
						label: "Continue Application",
						class: 'my-1 w-100 actions__continue-application-btn',
						confirm: false
					}

				};

			} else if (['Approved', 'Approved Conditional', 'Approved for Lesser Amount', 'Not Complete', 'Ready for Fulfilment', 'Waiting for Signature', 'Approved Pending Title'].includes(status)) {

				console.log('authUser', this.authUser);

				// if (edgeStatus == 'Docs Signed and Received') {

					obj.btns.applicationDashboard = {
						color: 'info',
							to: {
							name: 'ApplicationDashboard',
								params: {
								applicationId: id
							}
						},
						label: "Fulfilments",
							class: 'my-1- w-100 actions__continue-application-btn-',
								confirm: false
					};

				// }

			}



			let isPermissionsSuccessful = false;
			let count = 0;

			while (
				!isPermissionsSuccessful
				// && !this.stopAllRequests
				&& status != 'Incomplete'
				// && !this.isGettingSuccessful
			) {

				let res = false;

				// console.log('getting perms');
				count++;

				if (count > 1) {

					console.log('before');
					await this.wait(3000);
					console.log('after');

				}

				try {

					if (item.source) {
						const source = item.source;
					} else {
						// console.log('getPermissionBtns: creating new source');
						const source = await axios.CancelToken.source();
					}
					// res = await getPermissions(id, source);

				} catch (error) {
					console.log('error occured');
					continue;
				}

				isPermissionsSuccessful = true;

				if (res && res.permissions) {

					Object.keys(res.permissions).forEach(eventName => {

						if (eventName === 'evtResubmittoCredit') {

							let label = {
								en: 'Re-Submit with Updated Info',
								fr: 'Re-Submit with Updated Info',
							};

							obj.btns[eventName] = {
								color: 'info',
								//label: label,
								to: {
									name: 'ApplicationStep1',
									params: {
										applicationId: id
									}
								},
								class: 'my-1',
								confirm: false
							};

							return;

						}

						obj.btns[eventName] = {
							color: 'info',
							label: res.permissions[eventName][this.locale] ? res.permissions[eventName] : null,
							to: {
								name: 'ApplicationEvent',
								params: {
									applicationId: id,
									event: paramCase(eventName)
								}
							},
							class: 'my-1',
							confirm: true
						};

					});

				}

			}

			// obj.btns.uploads = {
			// 	color: 'info',
			// 	label: {
			// 		en: 'Dashboard'
			// 	},
			// 	to: {
			// 		name: 'ApplicationDashboard',
			// 		params: {
			// 			applicationId: id
			// 		}
			// 	},
			// 	class: 'my-1',
			// 	confirm: false
			// };

			this.$set(this.actions, id, obj);

			setTimeout(() => {

				const button = document.getElementById('get_perm_' + id)

				if (button) {
					button.style.display = 'none';
				}

				this.$set(this.actions, id, obj);
				this.$refs.databoxes && this.$refs.databoxes.$forceUpdate();

			});

			return obj;

		},

		getBtnAttrs(attrs) {

			attrs = Object.assign({ size: 'sm' }, attrs);

			delete attrs.to;
			delete attrs.label;

			return attrs;

		},

		getBtnMsg(btn, btnKey) {

			if (btn.msg) {
				return btn.msg
			}

			return null

		},

		getBtnLabel(btn, btnKey) {

			if (btn.label) {

				if (typeof btn.label === 'string') {

					return btn.label;

				}

				return btn.label[this.locale];

			}

			return this.$i18n.t('applications.' + btnKey + 'Btn');

		},

		checkWhitelist() {

			if (

				(
					this.authUser.vendorInternalOrExternal
					&& this.authUser.vendorInternalOrExternal == 'External Vendor'
				)

			) {

				return true;

			}

			return false;

		},

		async getProgramsAction() {

			this.isLoading = true;
			this.submitting = true;

			let result = await getPrograms().catch(async error => {

				// this.saveLoading(false);

				let errorMsg = error.toString();

				if (error.response && error.response.data && error.response.data.errorMessage) {

					errorMsg = error.response.data.errorMessage;

				}

				if (errorMsg) {

					this.errorAlert = await this.showErrorAlert(errorMsg);

				}

			});

			if (result.programs.length == 0) {
				this.errorAlert = await this.showErrorAlert('No programs were found');
				this.saveLoading(false);
				return;
			}

			Object.keys(result.programs).forEach(key => {

				let program = result.programs[key];

				switch (program.Code) {
					case '2':
						this.isLeaseFound = true
						break;
					case '32':
						this.isLoanFound = true
						break;
					case '36':
						this.isLoanFound = true
						break;
					case '37':
						this.isLoanFound = true
						console.log('found loan');
						break;

					case '38':
						this.isLoanFound = true
						break;
					default:
						break;
				}

				
			});

		}

	}

}

</script>

<style lang="scss" scoped>
.label {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 10px;
	padding: 3px 8px;
	border-radius: 10px;
	user-select: none;
	cursor: default;

	&.label-success {
		background: $success-color;
		color: #fff;
	}
}

.databoxes-length label {
	padding-top: 2px;
}

.hidden {
	opacity: 0;
	position: absolute;
	top: -1000;
	left: -1000;
	z-index: -1;
}

.databox-grid {

	.table-col-actions-column {
		height: 12rem;

		.btn {
			display: block;
			width: 100%;
			padding-left: 0.5rem;
			padding-right: 0.5rem;

			&+.btn {
				margin-top: 1rem !important;
			}
		}
	}

}

.status-tag {
	background: $light;
	border-radius: 4px;
	padding: 0.05em 0.5em;
	font-size: 12px;
	display: inline-flex;
	align-content: center;
	align-items: center;
	line-height: 1em;
	height: 2em;
	white-space: nowrap;

	&:before {
		content: "•";
		font-size: 20px;
		margin-right: 0.25em;
	}

	&.status-tag-incomplete {
		$color0: $gray-300;
		background: $color0;
		color: darken($color0, 60%);
	}

	&.status-tag-approved {
		$color1: #bff99f;
		background: $color1;
		color: darken($color1, 65%);
	}

	&.status-tag-funded {
		$color2: #b3e2ff;
		background: $color2;
		color: darken($color2, 60%);
	}

	&.status-tag-not-approved {
		$color3: #ffc4ba;
		background: $color3;
		color: darken($color3, 50%);
	}

	&.status-tag-cancelled {
		$color4: $gray-500;
		background: $color4;
		color: darken($color4, 50%);
	}

	&.status-tag-pending,
	&.status-tag-bureau-not-found {
		$color5: #fff2d1;
		background: $color5;
		color: darken($color5, 60%);
	}

	&.status-tag-submitted-to-kamsel {
		$color6: #f3ddff;
		background: $color6;
		color: darken($color6, 60%);
	}

}
</style>
